export const NON_FOLLOW_LIST_REQUEST = 'NON_FOLLOW_LIST_REQUEST'
export const NON_FOLLOW_LIST_SUCCESS = 'NON_FOLLOW_LIST_SUCCESS'
export const NON_FOLLOW_LIST_FAIL = 'NON_FOLLOW_LIST_FAIL'

export const FOLLOW_USER_REQUEST = 'FOLLOW_USER_REQUEST'
export const FOLLOW_USER_SUCCESS = 'FOLLOW_USER_SUCCESS'
export const FOLLOW_USER_FAIL = 'FOLLOW_USER_FAIL'
export const FOLLOW_USER_RESET = 'FOLLOW_USER_RESET'

export const FOLLOWING_USER_LIST_REQUEST = 'FOLLOWING_USER_LIST_REQUEST'
export const FOLLOWING_USER_LIST_SUCCESS = 'FOLLOWING_USER_LIST_SUCCESS'
export const FOLLOWING_USER_LIST_FAIL = 'FOLLOWING_USER_LIST_FAIL'

export const FOLLOWERS_USER_LIST_REQUEST = 'FOLLOWERS_USER_LIST_REQUEST'
export const FOLLOWERS_USER_LIST_SUCCESS = 'FOLLOWERS_USER_LIST_SUCCESS'
export const FOLLOWERS_USER_LIST_FAIL = 'FOLLOWERS_USER_LIST_FAIL'

export const UNFOLLOW_USER_REQUEST = 'UNFOLLOW_USER_REQUEST'
export const UNFOLLOW_USER_SUCCESS = 'UNFOLLOW_USER_SUCCESS'
export const UNFOLLOW_USER_FAIL = 'UNFOLLOW_USER_FAIL'
export const UNFOLLOW_USER_RESET = 'UNFOLLOW_USER_RESET'
